<!-- =========================================================================================
  File Name: EditHoverpack.vue
  Description: Form to edit hobject, placement and anchor in the same form
========================================================================================== -->

<template>
  <div>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center">
        <div class="vx-col md:w-1/2 w-full mb-base">
          <vx-card title="Edit Anchor">
            <form-anchor
              v-bind:convertImageToData64="false"
              @onAnchorChanged="onAnchorChanged"
              v-if="anchor != null"
              v-bind:anchor="anchor"
            ></form-anchor>
            <div class="vx-row mt-6">
              <div class="vx-col w-1/2">
                <vs-button color="warning" @click="$router.go(-1)" class="w-full mr-3 mb-2">Back</vs-button>
              </div>
              <div class="vx-col w-1/2">
                <vs-button @click="submitData()" class="w-full mr-3 mb-2">Save</vs-button>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import FormAnchor from '@/views/hoverlay/components/anchors-form/FormAnchor.vue'

export default {
  props: {},
  data() {
    return {
      anchor: null,
    }
  },
  computed: {
    placements() {
      return this.$store.state.hoverlay.placements
    },
    anchors() {
      return this.$store.state.hoverlay.anchors
    },
    hobjects() {
      return this.$store.state.hoverlay.hobjects
    },
    models() {
      return this.$store.state.hoverlay.models
    },
  },
  watch: {},
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    init() {
      // Find the anchor in vuex based on the anchor_pid
      if (this.$route.params.anchor_pid) {
        this.anchor = JSON.parse(
          JSON.stringify(this.anchors.filter(anchor => anchor.pid == this.$route.params.anchor_pid)[0])
        )
      }
    },
    onAnchorChanged(data) {
      console.log('onAnchorChanged')
      this.anchor.data = data
    },
    async submitData() {
      await this.updateAnchor(this.anchor)
    },
    async updateAnchor(anchor) {
      this.$vs.loading()
      console.log('anchor')
      console.log(anchor)
      if (!(anchor.data instanceof Blob)) delete anchor.data
      this.anchor = await this.$store.dispatch('hoverlay/updateAnchor', anchor)
      this.$vs.loading.close()
      this.$vs.notify({
        color: 'success',
        title: 'Success',
        text: 'The anchor has been updated! ',
      })
    },
  },
  components: { FormAnchor },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
